import React from "react";
import "./AirDrop.css";
import eag from "../../assets/airdrop-dacrazy/daCrazyRightImg.svg";
import dragonblack from "../../assets/airdrop-dacrazy/coinBIgLeft.svg";
import btc from "../../assets/airdrop-dacrazy/DaCrazy sm.svg";
import wibsIcon from "../../assets/airdrop-dacrazy/daCrazymidsm.svg";
import cal_light from "../../assets/airdrop-dacrazy/9December.svg";

import CountdownTimer from "./CountdownTimer";
import ReadOnlyCalendar from "./ReadOnlyCalendar";
import ContactUs from "./ContactUs/ContactUs";
import { Button, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";

const DaCrazyAirdrop = () => {
  const targetDate = new Date("2024-12-14T18:59:59");

  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <div
      className="d-flex justify-content-center"
      style={{ marginTop: "70px", background: "white", color: "black" }}
    >
      <div style={{ maxWidth: `${isMobile ? "84%" : "1550px"}` }}>
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ width: isMobile ? "100%" : "50%" }}>
            {
              <div style={{ width: "100%" }}>
                <img alt="eagle" src={dragonblack} width="100%" />
              </div>
            }
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: isMobile ? "100%" : "49%",
              justifyContent: "center",
              alignItems: "center",
              marginTop: isMobile ? "" : "120px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                className={
                  isMobile ? "font_47x fw-bold text-center" : "font_60x fw-bold"
                }
              >
                <span
                  style={{
                    fontWeight: "normal",
                    fontSize: "45px",
                    fontFamily: "tahu",
                  }}
                >
                  Da Crazy Hawaiian
                </span>{" "}
                <br />
                Coaching Day Airdrop
              </div>
              <p
                className={isMobile ? "font_16x" : "font_20x"}
                style={{ color: "black" }}
              >
                Exciting News! Participate in the DaCrazy Coin Pre Sale and get
                a huge discount!
              </p>
              <div style={{ margin: "70px auto 30px" }}>
                <CountdownTimer targetDate={targetDate} />
              </div>
              <Button
                variant="contained"
                component={Link}
                to="/airdrop-register-dacrazy-exch"
                disableTouchRipple
                sx={{
                  backgroundColor: "#000",
                  borderRadius: "2px",
                  width: `${isMobile ? "100%" : "316px"}`,
                  px: 10,
                  py: 1,
                  ml: `${isMobile ? "0%" : "27.2%"}`,
                  textTransform: "none",
                  fontSize: "13px",
                  fontWeight: 700,
                  boxShadow: "none",
                  color: "white",
                  mt: 5,
                  height: `${isMobile ? "55px" : "40px"}`,
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#000",
                    opacity: ".7",
                  },
                }}
                disabled={true}
                black
              >
                Register for Airdrop
              </Button>
            </div>
            <div style={{ alignSelf: "center", margin: "100px 0px" }}>
              {isMobile ? (
                <div className="d-flex align-items-center flex-column">
                  <img alt="inex" src={btc} width={"162px"} />
                  <div className={isMobile ? "font_100x fw-bold" : "font_200x"}>
                    $ 0.00081
                  </div>
                  <div className="font_20x">Estimated Value</div>
                </div>
              ) : (
                <div>
                  <div className="font_20x">
                    <img alt="inex" src={btc} width={"62px"} /> Estimated Value
                  </div>
                  <div className="font_150x">$0.00081</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="whatsWhoIsBitcoinSatoshi">
          <div>
            <img src={wibsIcon} />
          </div>
          <div className="what-inex-1">
            <div
              className={isMobile ? "font_37x fw-bold" : "font_70x"}
              style={{ fontSize: "35px" }}
            >
              <span style={{ fontFamily: "tahu", fontSize: "70px" }}>
                DaCrazy Coin
              </span>
            </div>
            <div className="font_17x">
              In the heart of the Hawaiian Islands, on the beaches of Oahu,
              lived a young man named Koa. Inspired by his ancestors and the
              spirit of 'mana,' Koa became known for his strength and charisma,
              eventually earning the title
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                gap: "15px",
              }}
            >
              <Button
                variant="contained"
                component={Link}
                to="/airdrop-register-dacrazy-exch"
                disableTouchRipple
                sx={{
                  backgroundColor: "#000",
                  borderRadius: "2px",
                  width: `${isMobile ? "50%" : "256px"}`,
                  py: 1,
                  textTransform: "none",
                  fontSize: "13px",
                  fontWeight: 700,
                  boxShadow: "none",
                  color: "white",
                  height: `${isMobile ? "55px" : "40px"}`,
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#000",
                    opacity: ".7",
                  },
                }}
                black
                disabled={true}
              >
                Register for Airdrop
              </Button>
              <a
                style={{ color: "#000" }}
                href="https://dacrazyhawaiian.wtf/coin"
              >
                Learn More &gt;
              </a>
            </div>
          </div>
        </div>
        {/* Above component  */}
        <div
          style={{
            width: "100%",
            maxWidth: "1200px",
            margin: "100px auto 50px",
          }}
        >
          <div className="UnvelingTheContainer">
            <div style={{ flex: 2 }}>
              <h3
                style={{
                  fontSize: !isMobile ? "50px" : "32px",
                  fontWeight: "bold",
                  fontFamily: "tahu",
                  color: "black",
                }}
              >
                The Crazy Hawaiian.
              </h3>
              <p style={{ fontSize: !isMobile ? "17px" : "15px" }}>
                Starting from local competitions, Koa mastered the art of the
                power slap, drawing on ancient warrior traditions. His fame
                spread quickly, echoing the resilient spirit of the islands.
                Faced with global challenges, Koa saw opportunity in the rising
                tide of cryptocurrency. Thus, Da Crazy Hawaiian MemeCoin was
                born, a playful yet powerful digital embodiment of his journey.
                With no transaction fees and robust stability, this memecoin
                rapidly attracted crypto enthusiasts worldwide. As Koa conquered
                power slap competitions, eventually becoming the Heavyweight
                Slap King, Da Crazy Hawaiian MemeCoin surged alongside his
                legendary status. Today, the story of The Crazy Hawaiian and his
                MemeCoin inspires many, proving that humble beginnings can make
                waves of change and joy globally.
              </p>

              <Button
                variant="contained"
                component={Link}
                to="/airdrop-register-dacrazy-exch"
                disableTouchRipple
                sx={{
                  backgroundColor: "#000",
                  borderRadius: "2px",
                  // color: "#282828",
                  width: `${isMobile ? "50%" : "256px"}`,

                  py: 1,
                  mt: 5,
                  textTransform: "none",
                  fontSize: "13px",
                  fontWeight: 700,
                  boxShadow: "none",
                  color: "white",
                  height: `${isMobile ? "55px" : "40px"}`,
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#000",
                    opacity: ".7",
                  },
                }}
                black
                disabled={true}
              >
                Register for Airdrop
              </Button>
            </div>
            <div style={{ flex: 1 }}>
              <img
                src={eag}
                alt="eagle"
                className="req-img"
                style={{ width: "100%" }}
              />
            </div>
          </div>

          <img
            alt="cal"
            src={cal_light}
            style={{ width: "90%", display: "block", margin: "0 auto" }}
          />
        </div>
        <div className="my-calendar">
          {isMobile ? (
            <>
              <div
                className="font_50x fw-bold align-self-start"
                style={{ color: "black" }}
              >
                Key dates
              </div>
              <div
                className="font_31x align-self-start"
                style={{ color: "black" }}
              >
                for Pre Sale
              </div>
              <div
                className="font_20x mb-5 align-self-start"
                style={{ color: "black" }}
              >
                Don’t miss out any of these dates
              </div>
            </>
          ) : (
            <>
              <div className="font_70x fw-bold" style={{ color: "black" }}>
                Key dates for Pre Sale
              </div>
              <div className="font_20x mb-5 " style={{ color: "black" }}>
                Don’t miss out any of these dates
              </div>
            </>
          )}
          <ReadOnlyCalendar />
        </div>
        <div>
          <ContactUs />
        </div>{" "}
      </div>
    </div>
  );
};

export default DaCrazyAirdrop;
